import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../constants';
import './CustomerCheckPage.css';
import logo from '../images/logo.jpg';

const CustomerCheckPage = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  //const restaurantName = segments[1];
  const restaurant_id = segments[1];
  const key_num = segments[2];
  const [text, setText] = useState('');
  const [isVehicleExists, setIsVehicleExists] = useState(true);
  const [showModal, setShowModal] = useState(false);

  let screenWidth = window.innerWidth;
  let screenHeight = window.innerHeight;

  function setScreenHeight(num) {
    return ((num*screenHeight/932) + 'vh')
  }
  function setFontSize(num) {
    return ((num*(screenHeight/932)) + 'rem')
  }
  function setScreenWidth(num) {
    return ((num*screenWidth/430) + 'vw')
  }

  document.documentElement.style.setProperty('--dynamic-pre-Welcome-text-box-font', setFontSize(1.9));
  document.documentElement.style.setProperty('--dynamic-input-form-height', setScreenHeight(100));
  document.documentElement.style.setProperty('--dynamic-input-form-margin-top', setScreenHeight(16));
  document.documentElement.style.setProperty('--dynamic-input-height', setScreenHeight(20));
  document.documentElement.style.setProperty('--dynamic-input-margin-bottom', setScreenHeight(6));
  document.documentElement.style.setProperty('--dynamic-input-label-font', setFontSize(2.0));
  document.documentElement.style.setProperty('--dynamic-input-label-margin-bottom', setScreenHeight(3));
  document.documentElement.style.setProperty('--dynamic-styled-input-margin-top', setScreenHeight(3));
  document.documentElement.style.setProperty('--dynamic-styled-input-height', setScreenHeight(6));
  document.documentElement.style.setProperty('--dynamic-styled-input-focus-font', setFontSize(2.1));
  document.documentElement.style.setProperty('--dynamic-styled-button-font', setFontSize(1.7));
  document.documentElement.style.setProperty('--dynamic-styled-button-height', setScreenHeight(5));
  document.documentElement.style.setProperty('--dynamic-styled-button-width', setScreenWidth(40));
  document.documentElement.style.setProperty('--dynamic-styled-button-margin-top', setScreenHeight(3.2));


  if(screenHeight < 700) {
    document.documentElement.style.setProperty('--dynamic-pre-header-data-box-height', setScreenHeight(14));
    document.documentElement.style.setProperty('--dynamic-pre-header-section-height', setScreenHeight(16));
  }
  else {
    document.documentElement.style.setProperty('--dynamic-pre-header-data-box-height', setScreenHeight(12));
    document.documentElement.style.setProperty('--dynamic-pre-header-section-height', setScreenHeight(14));
    
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(constants.CUSTOMER.FETCH_JOB_URL+`${restaurant_id}/${key_num}/${text}`, {
        headers: { 'Authorization': constants.CUSTOMER.TOKEN }
      });
      if (response.ok) {
        const result = await response.json();
        if (result.data && result.data.result && result.data.result.length > 0) {
          navigate(`${pathname}${text}`);
        } else {
          setIsVehicleExists(false);
          setShowModal(true);
        }
      } else {
        console.error('Failed to fetch job status:', await response.text());
      }
    } catch (error) {
      console.error('Error fetching job status:', error);
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  return (
    <div className="check-in-page">
      <div className="pre-header-section">
        <div className="pre-header-data-box">
          <div className="pre-Welcome-text-box">
            <h1>Welcome !!! 👋</h1>
            {/* <p>Check out your vehicle progress with the following access</p> */}
          </div>
          <div className="pre-logo-section">
            <img src={logo} alt="Valet Hook Logo" />
          </div>
        </div>
      </div>

      {/* <h1>Vallet Hook</h1> */}
      <form onSubmit={handleLogin} className="input-form">
        <div className='input'>
          <label>Please Enter your 4 digit Vehicle Number</label>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
            maxLength="4"
            className="styled-input"
          />
        </div>
        <button type="submit" className="styled-button">Submit</button>
      </form>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Car not yet updated</h2>
            <button className="close-button" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerCheckPage;
