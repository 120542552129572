import localConfig from './config/env/local';
import devConfig from './config/env/dev';
import prodConfig from './config/env/prod.json';

function loadConfig() {
 
  const env = process.env.REACT_APP_ENV;  
  switch (env) {
    case 'local':
      return localConfig;
    case 'dev':
      return devConfig;
    case 'prod':
      return prodConfig;
    default:
      console.warn(`Unknown environment '${env}', falling back to 'local' config.`);
      return devConfig;
  }
}

const config = loadConfig();
export default config;
