import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CustomerPage from './pages/CustomerPage';
import CustomerCheckPage from './pages/CustomerCheckPage';
import NoVehicle from './pages/NoVehicle';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/:restaurant_id/:key_num/:vehicle_num" element={<CustomerPage />} />
          <Route path="/:restaurant_id/:key_num" element={<CustomerCheckPage />} />
          <Route path="/:restaurant_id/:key_num/:vehicle_num/nojob" element={<NoVehicle />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
