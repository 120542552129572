import config from './configLoader'; // Load the environment-specific config
const baseUrl = config.URL
const customerToken= config.customer.token;

const CUSTOMER = {
    FETCH_JOB_URL: `${baseUrl}/api/v1/jobs/`,
    RETURN_REQUEST_URL: `${baseUrl}/api/v1/jobs/return`,
    FEED_BACK_URL: `${baseUrl}/api/v1/feedback`,
    TOKEN:`${customerToken}`,
    FETCH_ETS_URL: `${baseUrl}/api/v1/jobs/ets`
}

export default {
    CUSTOMER
  };