import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { useNavigate } from 'react-router-dom';
import './CustomerPage.css';
import logo from '../images/logo.jpg';
import circleGreen from '../images/Circle.jpg'; 
import constants from '../constants';

const CustomerPage = () => {
  const navigate = useNavigate(); 
  const [status, setStatus] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [jobId, setJobId] = useState('');
  const [otp, setOtp] = useState('');
  const [time,SetTime] = useState('');
  const [isVehicleExists, SetIsVehicleExists] = useState(true)
  const [parkedTime,SetParkedTime] = useState('');
  const [requestedTime,SetRequestedTime] = useState('');
  const [pickedTime,SetPickedTime] = useState('');
  const [readyTime,SetReadyTime] = useState('');
  const [doneTime,SetDoneTime] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [hasRequestedReturn, setHasRequestedReturn] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [showTripPopup, setShowTripPopup] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [enableTrip, SetRestaurantType]= useState(false);
  const [minutes, setMinutes] = useState(10); // Default to 40 minutes
  const [seconds, setSeconds] = useState(0);
  const [eta, setEta] = useState(10);
  const [timerStarted, setTimerStarted] = useState(false); 
  const [startTime, setStartTime] = useState(null); 
  const [restaurantCode, SetRestaurantCode] = useState(null);

  const feedBackJob = `feedBack_${jobId}`; // Unique key for each jobId
  if (localStorage.getItem(feedBackJob) === null) {
    localStorage.setItem(feedBackJob, false);
  }
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  const restaurant_id = segments[1];
  const key_num= segments[2];
  const vehicle_num = segments[3];
  let screenWidth = window.innerWidth;
  let screenHeight = window.innerHeight;

  function setScreenHeight(num) {
    return ((num*screenHeight/932) + 'vh')
  }
  function setFontSize(num) {
    return ((num*(screenHeight/932)) + 'rem')
  }
  function setScreenWidth(num) {
    return ((num*screenHeight/932) + 'vw')
  }
  let timeFont = (10*(screenHeight/932))+'px'
  let clockFont = (7*(screenHeight/932))+'px'

  document.documentElement.style.setProperty('--dynamic-Welcome-text-box-font', setFontSize(1.8));
  document.documentElement.style.setProperty('--dynamic-info-value-font', setFontSize(1.2));
  document.documentElement.style.setProperty('--dynamic-info-box-font', setFontSize(1.0));
  document.documentElement.style.setProperty('--dynamic-info-value-height', setScreenHeight(3.9));
  document.documentElement.style.setProperty('--dynamic-progress-bar-heading-font', setFontSize(2.0));
  document.documentElement.style.setProperty('--dynamic-progress-steps-box-font', setFontSize(1.2));
  document.documentElement.style.setProperty('--dynamic-progress-steps-circle-font', setFontSize(1.0));
  document.documentElement.style.setProperty('--dynamic-request-buttons-wrapper-magin', setScreenHeight(4.0));
  document.documentElement.style.setProperty('--dynamic-request-return-button-height', setScreenHeight(5.5));
  document.documentElement.style.setProperty('--dynamic-request-return-button-waiting-font', setFontSize(1.1));
  document.documentElement.style.setProperty('--dynamic-request-return-button-waiting-disabled-font', setFontSize(1.1));
  document.documentElement.style.setProperty('--dynamic-request-return-button-font', setFontSize(1.2));
  document.documentElement.style.setProperty('--dynamic-request-timer-font', setFontSize(1.7));

  document.documentElement.style.setProperty('--dynamic-promotion-box-container-height', setScreenHeight(18));

  document.documentElement.style.setProperty('--dynamic-star-box-size', setScreenWidth(8));
  document.documentElement.style.setProperty('--dynamic-star-size', setFontSize(2.0));
  document.documentElement.style.setProperty('--dynamic-feedback-container-height', setScreenHeight(20));
  document.documentElement.style.setProperty('--dynamic-feedback-input-height', setScreenHeight(5));
  document.documentElement.style.setProperty('--dynamic-feedback-input-marginntop', setScreenHeight(1.7));
  document.documentElement.style.setProperty('--dynamic-feedback-input-font', setFontSize(1.5));
  document.documentElement.style.setProperty('--dynamic-submit-feedback-button-height', setScreenWidth(9));
  document.documentElement.style.setProperty('--dynamic-submit-feedback-button-margin-top', setScreenWidth(4.4));
  document.documentElement.style.setProperty('--dynamic-submit-feedback-button-font', setFontSize(1.0));

  if(screenHeight < 700) {
    document.documentElement.style.setProperty('--dynamic-header-data-box-height', setScreenHeight(12));
    document.documentElement.style.setProperty('--dynamic-header-section-height', setScreenHeight(14));
  }
  else {
    document.documentElement.style.setProperty('--dynamic-header-data-box-height', setScreenHeight(10));
    document.documentElement.style.setProperty('--dynamic-header-section-height', setScreenHeight(12));
    
  }

  const onSearch = () => {
    console.log("Print adderss")
  };

  useEffect(() => {
    if (!timerStarted) return; 
    const timerKey = `countdownTime_${jobId}`;
    const etaKey = `countdownEta_${jobId}`;
    const storedTime = localStorage.getItem(timerKey);
    const currentTime = new Date().getTime();
  
    if (storedTime) {
      const timeDiff = (currentTime - storedTime) / 1000; // Calculate time difference in seconds
      const remainingTime = (localStorage.getItem(etaKey) * 60) - timeDiff; // 15 minutes in seconds minus elapsed time
  
      if (remainingTime > 0) {
        setMinutes(Math.floor(remainingTime / 60));
        setSeconds(Math.floor(remainingTime % 60));
      } else {
        setMinutes(0);
        setSeconds(0);
      }
    } else {
      localStorage.setItem(timerKey, currentTime);
      localStorage.setItem(etaKey, eta);
      setStartTime(currentTime);
      setMinutes(eta);
      setSeconds(0);
    }
  }, [jobId, timerStarted]);
  
  useEffect(() => {
    if (!timerStarted) return; // Don't start the countdown if timer hasn't started

    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      } else if (minutes > 0) {
        setMinutes((prev) => prev - 1);
        setSeconds(59);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [minutes, seconds, timerStarted]);
  
  // Format time as mm:ss
  const formatTimer = (min, sec) => {
    // Start the countdown timer after the first call
    if (!timerStarted) {
      setTimerStarted(true); // Mark the timer as started
    }

    // Format time as mm:ss
    return `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
  };
  

  function formatTime(isoString) {
    // Create a new Date object from the ISO string (UTC by default)
    const date = new Date(isoString);

    // Create a new Date by adding the IST offset to the UTC time
    const istDate = new Date(date.getTime());

    // Extract hours and minutes from the IST date
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Pad minutes with leading zeros if needed
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Format the time string
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;

    return formattedTime;
}

  const styles = {
    parked: {
      color: 'rgba(255, 87, 51, 1)',
    },
    return: {
      color: 'rgba(255, 215, 0, 1)',
    },
    pickedUp: {
      color: 'rgba(142, 68, 173, 1)',
    },
    ready: {
      color: 'rgba(61, 177, 121, 1)',
    },
    done: {
      color: 'rgba(40, 180, 99, 0.73)',
    },
    clock: {
      fontSize: clockFont,
    },
    parkedTime: {
      fontSize: timeFont,
      fontweight : '600px',
      color: 'rgba(255, 87, 51, 1)',  // You can choose a different color for the time if needed
      float: 'left', // Pushes the time text to the right
    },
    returnTime: {
      fontSize: timeFont,
      fontweight : '600px',
      color: 'rgba(255, 215, 0, 1)',// You can choose a different color for the time if needed
      float: 'left', // Pushes the time text to the right
    },
    pickedUpTime: {
      fontSize: timeFont,
      fontweight : '600px',
      color: 'rgba(142, 68, 173, 1)',  // You can choose a different color for the time if needed
      float: 'left', // Pushes the time text to the right
    },
    readyTime: {
      fontSize: timeFont,
      fontweight : '600px',
      color: 'rgba(61, 177, 121, 1)',  // You can choose a different color for the time if needed
      float: 'left', // Pushes the time text to the right
    },
    doneTime: {
      fontSize: timeFont,
      fontweight : '600px',
      color: 'rgba(40, 180, 99, 0.73)',  // You can choose a different color for the time if needed
      float: 'left', // Pushes the time text to the right
    }
  };

  const fetchAndCheckStatus = async () => {
    console.log('status is :',status)
    await fetchJobStatus();
    if (status === 'RETURN') {
      setIsRefreshing(true); // Disable refreshing if the status is 'DONE'
    }
    if (status === 'DONE' || status === 'READY_TO_RETURN') {
      setIsRefreshing(false); // Disable refreshing if the status is 'DONE'
    }
  };

  useEffect(() => {
    

    fetchAndCheckStatus();

    const intervalId = setInterval(() => {
      if (isRefreshing) {
        fetchAndCheckStatus();
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, [restaurant_id, isRefreshing, status]);

  const fetchJobStatus = async () => {
    try {
      const response = await fetch(constants.CUSTOMER.FETCH_JOB_URL+`${restaurant_id}/${key_num}/${vehicle_num}`, {
        headers: { 'Authorization': constants.CUSTOMER.TOKEN }
      });
      if (response.ok) {
        const result = await response.json();
        if (result.data && result.data.result && result.data.result.length > 0) {
          setStatus(result.data.result[0].status);
          setJobId(result.data.result[0]._id);
          setRestaurantName(result.data.result[0].restaurant.name);
          setOtp(result.data.result[0].otp);
          SetTime(result.data.result[0].return_ets);
          SetRestaurantType(result.data.result[0].restaurant.type === 'PUB');
          SetRestaurantCode(result.data.result[0].restaurant._id)
          console.log('refreshed');
          switch (result.data.result[0].status) {
            case "PARKED":
              SetParkedTime(result.data.result[0].parked_ets)
              break;
            case "RETURN":
              SetParkedTime(result.data.result[0].parked_ets)
              SetRequestedTime(result.data.result[0].return_ets)
              break;
            case "PICKED_UP":
              SetParkedTime(result.data.result[0].parked_ets)
              SetRequestedTime(result.data.result[0].return_ets)
              SetPickedTime(result.data.result[0].pickedup_ets)
              break;
            case "READY_TO_RETURN":
              SetParkedTime(result.data.result[0].parked_ets)
              SetRequestedTime(result.data.result[0].return_ets)
              SetPickedTime(result.data.result[0].pickedup_ets)
              SetReadyTime(result.data.result[0].ready_ets)
              break;  
            case "DONE":
              SetParkedTime(result.data.result[0].parked_ets)
              SetRequestedTime(result.data.result[0].return_ets)
              SetPickedTime(result.data.result[0].pickedup_ets)
              SetReadyTime(result.data.result[0].ready_ets)
              SetDoneTime(result.data.result[0].done_ets)
              break;
            default:
              SetTime(result.data.result[0].return_ets); // You can assign a default value if status doesn't match any case
              break;
          }
        } else {
          navigate(`${pathname}/nojob`);
          console.error('No job found for the unique_id');
        }
      } else {
        console.error('Failed to fetch job status:', await response.text());
      }
    } catch (error) {
      console.error('Error fetching job status:', error);
    }
  };

  const handleRequestReturn = async () => {
    try {
      const raw = JSON.stringify({
        "id": jobId
      });
      console.log(constants.CUSTOMER.RETURN_REQUEST_URL)
      const response = await fetch(constants.CUSTOMER.RETURN_REQUEST_URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': constants.CUSTOMER.TOKEN,
        },
        body: raw
      });

      if (response.ok) {
        setStatus('RETURN');
        setHasRequestedReturn(true);
        await fetchJobStatus();
      } else {
        console.error('Failed to request return:', await response.text());
      }
    } catch (error) {
      console.error('Error requesting return:', error);
    }
  };

  const fetchETS = async () => {
    try {
      const response = await fetch(constants.CUSTOMER.FETCH_ETS_URL+`?job_id=${jobId}&restaurant_id=${restaurantCode}`, {
        headers: { 'Authorization': constants.CUSTOMER.TOKEN }
      });
      if (response.ok) {
        const result = await response.json();
        setEta(result.data.result);
      } else {
        console.error('Failed to request return:', await response.text());
      }
      setPopupOpen(true)
    } catch (error) {
      console.error('Error requesting return:', error);
    }
  };

  const handleRequestTrip = async () => {
    console.log(selectedAddress);
  };

  const handleRatingClick = (ratingValue) => {
    if (!isFeedbackSubmitted) {
      setRating(ratingValue);
      console.log(ratingValue)
    }
  };

  const handleFeedbackChange = (e) => {
    if (!isFeedbackSubmitted) {
      setFeedback(e.target.value);
    }
  };

  const handleSubmitFeedback = async () => {
    if (rating > 0) {
      try {
        const raw = JSON.stringify({
          "job_id": jobId,
          "restaurant_id": restaurantCode,
          "rating": `${rating}`,
          "feedback": feedback
        });

        const response = await fetch(constants.CUSTOMER.FEED_BACK_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': constants.CUSTOMER.TOKEN,
          },
          body: raw
        });

        if (response.ok) {
          setIsFeedbackSubmitted(true);
          localStorage.setItem(feedBackJob, true);
        } else {
          console.error('Failed to submit feedback:', await response.text());
        }
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    }
  };

  const isParkedChecked = status === 'PARKED' || status === 'RETURN' || status === 'PICKED_UP' || status === 'READY_TO_RETURN' || status === 'DONE';
  const isRequestedChecked = status === 'RETURN' || status === 'PICKED_UP' || status === 'READY_TO_RETURN' || status === 'DONE';
  const isPickedUpChecked = status === 'PICKED_UP' || status === 'READY_TO_RETURN' || status === 'DONE';
  const isReturnedReadyToReturnChecked = status === 'READY_TO_RETURN' || status === 'DONE';
  const isReturnedChecked = status === 'DONE';
  // const isParkedChecked = true;
  // const isRequestedChecked = true;
  // const isPickedUpChecked = true;
  // const isReturnedReadyToReturnChecked = true;
  // const isReturnedChecked = false;
  const canRequestReturn = (status === 'OPEN' || status === 'PARKED') && !hasRequestedReturn;
  //const canRequestReturn =true;
  const canSubmitFeedback = (status === 'READY_TO_RETURN' || status === 'DONE') && !isFeedbackSubmitted;
  const canRequestTrip = canRequestReturn; // "Request Trip" enabled if "Request Return" is enabled

  return (
    <div className="customer-page">
      <div className="header-section">
        <div className="header-data-box">
          <div className="Welcome-text-box">
            <h1>Welcome to <br/>{restaurantName}! 👋</h1>
          </div>
          <div className="logo-section">
            <img src={logo} alt="Valet Hook Logo" />
          </div>
        </div>
      </div>

      {/* OTP, Key Number, Vehicle Number Boxes */}
      <div className="info-boxes">
        
        <div className="info-box">
          <h3> <span>OTP</span></h3>
          <div className="info-value">{otp}</div>
        </div>
        
        <div className="info-box">
          <h3>Key No</h3>
          <div className="info-value">#{key_num}</div>
        </div>
        
        <div className="info-box">
          <h3>Car No</h3>
          <div className="info-value">{vehicle_num}</div>
        </div>
      </div>
      <div className="progress-bar-heading"> 
        <h3>Vehicle Status </h3>
      </div>
      <div className="progress-container">
        
        <div className="progress-step">
          <div className={`line ${isParkedChecked ? 'completed' : ''}`}></div>
          <div className={`circle ${isParkedChecked ? 'completed' : ''}`}
            style={isParkedChecked ? { backgroundImage: `url(${circleGreen})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'transparent', border: 'none' } : {}} >
            {!isParkedChecked && '1'}
          </div>
          <div className={`box ${isParkedChecked ? 'completed' : ''}`}>
            <div style={styles.parked}>PARKED</div>
            {isParkedChecked && <div style={styles.parkedTime}><span style={styles.clock}>🕒</span> {formatTime(parkedTime)} </div>}
          </div>
        </div>
        <div className="progress-step">
          <div className={`line ${isRequestedChecked ? 'completed' : ''}`}></div>
          <div className={`circle ${isRequestedChecked ? 'completed' : ''}`}
            style={isRequestedChecked ? { backgroundImage: `url(${circleGreen})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'transparent', border: 'none' } : {}} >
            {!isRequestedChecked && '2'}
          </div>
          <div className={`box  ${isRequestedChecked ? 'completed' : ''}`}> 
            <div style={styles.return}>REQUESTED</div>
            {isRequestedChecked && <div style={styles.returnTime}><span style={styles.clock}>🕒</span> {formatTime(requestedTime)} </div>}
          </div>
        </div>
        <div className="progress-step">
          <div className={`line ${isPickedUpChecked ? 'completed' : ''}`}></div>
          <div className={`circle ${isPickedUpChecked ? 'completed' : ''}`}
            style={isPickedUpChecked ? { backgroundImage: `url(${circleGreen})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'transparent', border: 'none' } : {}} >
            {!isPickedUpChecked && '3'}
          </div>
          <div className={`box  ${isPickedUpChecked ? 'completed' : ''}`}> 
            <div style={styles.pickedUp}>PICKED UP</div>
            {isPickedUpChecked && <div style={styles.pickedUpTime}><span style={styles.clock}>🕒</span> {formatTime(pickedTime)} </div>}
          </div>
        </div>
        <div className="progress-step">
          <div className={`line ${isReturnedReadyToReturnChecked ? 'completed' : ''}`}></div>
          <div className={`circle ${isReturnedReadyToReturnChecked ? 'completed' : ''}`}
            style={isReturnedReadyToReturnChecked ? { backgroundImage: `url(${circleGreen})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'transparent', border: 'none' } : {}} >
            {!isReturnedReadyToReturnChecked && '4'}
          </div>
          <div className={`box  ${isReturnedReadyToReturnChecked ? 'completed' : ''}`}> 
            <div style={styles.ready}>READY FOR RETURN</div>
            {isReturnedReadyToReturnChecked && <div style={styles.readyTime}><span style={styles.clock}>🕒</span> {formatTime(readyTime)} </div>}
          </div>
        </div>
        <div className="progress-step">
          <div className={`circle ${isReturnedChecked ? 'completed' : ''}`}
            style={isReturnedChecked ? { backgroundImage: `url(${circleGreen})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'transparent', border: 'none' } : {}} >
            {!isReturnedChecked && '5'}
          </div>
          <div className={`box  ${isReturnedChecked ? 'completed' : ''}`}> 
            <div style={styles.done}>DONE</div>
            {isReturnedChecked && <div style={styles.doneTime}><span style={styles.clock}>🕒</span> {formatTime(doneTime)} </div>}
          </div>
        </div>
      </div>
      <div className="request-buttons-wrapper">
        {!isRequestedChecked && <div className="request-box-container" >
          {/* <p> Click the button to request your vehicle </p> */}
          <button
            className={`request-return-button ${canRequestReturn ? '' : 'disabled'}`}
            onClick={() => fetchETS()}
            disabled={!canRequestReturn}
          >
            GET MY CAR
          </button>
        </div>  }
        {isRequestedChecked && !isReturnedReadyToReturnChecked &&<div className="request-box-container" >
          {/* <p> Click the button to request your vehicle </p> */}
          <button
            className={`request-return-button`}
            disabled={!canRequestReturn}
          >
            {formatTimer(minutes, seconds)}
          </button>
        </div> } 
        {isReturnedReadyToReturnChecked && !isReturnedChecked &&<div className="request-box-container" >
          {/* <p> Click the button to request your vehicle </p> */}
          <button
            className={`request-return-button-waiting`}
            disabled={!canRequestReturn}
          >
            CAR WAITING
          </button>
        </div> } 
        {isReturnedChecked &&<div className="request-box-container" >
          {/* <p> Click the button to request your vehicle </p> */}
          <button
            className={`request-return-button-waiting`}
            disabled={!canRequestReturn}
          >
            CAR Returned
          </button>
        </div> } 
        { enableTrip && <div className="request-box-container">
          <p> Click the button to request Trip your vehicle </p>
          <button
            className={`request-trip-button ${canRequestTrip ? '' : 'disabled'}`}
            onClick={() => setPopupOpen(true)}
            disabled={!canRequestTrip}
          >
            Request Trip
          </button> 
        </div>}
      </div>
      
      
      { (isReturnedReadyToReturnChecked && (localStorage.getItem(feedBackJob)==="false")) &&<div className={`feedback-container ${canSubmitFeedback ? '' : 'disabled'}`}>
        {/* <h2>Feedback Details</h2> */}
        <div className="rating-stars">
          {[1, 2, 3, 4, 5].map((star) => (
          <div key={star} className="star-box"> {/* Add star-box around each star */}
            <span
              className={`star ${rating >= star ? 'filled' : ''}`}
              onClick={() => handleRatingClick(star)}
            >
              ★
            </span>
          </div>
          ))}
        </div>
        <textarea className="feedback-input"
          placeholder="Give your feedback"
          value={feedback}
          onChange={handleFeedbackChange}
          disabled={isFeedbackSubmitted}
        />
        <button
          className="submit-feedback-button"
          onClick={handleSubmitFeedback}
          disabled={isFeedbackSubmitted}
        >
          Submit
        </button> 
      </div> }
      { (!isReturnedReadyToReturnChecked ||(localStorage.getItem(feedBackJob)==="true") )&&<div className="promotion-boxes-wrapper">
        <div className="promotion-box-container" >
          <p>  </p>  
        </div>  
      </div> }
      
      {popupOpen && (
        <div className="popup-overlay-customer" onClick={() => setPopupOpen(false)}>
          <div className="popup-content-customer" onClick={(e) => e.stopPropagation()}>
            <h3 className="popup-message">Your Car will be received in {eta} minutes</h3>
            {/* <input className="search-input"
              type="text"
              placeholder="Enter location"
              value={selectedAddress}
              onChange={(e) => setSelectedAddress(e.target.value)}
            /> */}
            <h3 className="popup-message">Please ensure to make your request at the time of Billing</h3>
            <button className="confirm-button" onClick={() => {
              handleRequestReturn();
              setPopupOpen(false);
            }}>Request</button>
            <button className="cancel-button" onClick={() => {
              setPopupOpen(false);
            }}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerPage;
